import { useEffect, useState } from "react";
import Swal from "sweetalert2"; // Import Swal for alerts
import {
  AddUserData,
  deleteUser,
  GetUserData,
  UpdateUserData,
} from "../../services/userSevices";

const UsersPage = () => {
  const [usersData, setUsersData] = useState([]); // Data for users
  const [editId, setEditId] = useState(null); // ID of the record to be edited
  const [formData, setFormData] = useState({
    id: null,
    name: "",
    email: "",
    phoneNo: "",
    address: "",
  });

  const [isAdding, setIsAdding] = useState(false); // To determine if it's adding a new User

  // Fetch data from API on component mount
  useEffect(() => {
    fetchUsersData();
  }, []);

  // Function to fetch users data
  const fetchUsersData = async () => {
    try {
      const response = await GetUserData(); // Fetch data from the API
      if (response.status === "SUCCESS") {
        const data = response.data; // Get the user data

        // Initialize or reinitialize DataTable with new data
        var table = $("#user_table").DataTable({
          data: data, // Set the table data
          destroy: true, // Destroy previous DataTable instance to prevent conflicts
          columns: [
            { data: "id" }, // First column: user id
            { data: "name" }, // Second column: user name
            { data: "email" }, // Third column: user email
            { data: "phoneNo" }, // Fourth column: user phone number
            { data: "address" }, // Fifth column: user address
            {
              data: null,
              defaultContent: `<button class="me-3 btn btn-success"><i class="fa fa-edit"></i> Edit</button>
                               <button class="me-3 btn btn-danger"><i class="fa fa-remove"></i> Delete</button>`,
            }, // Last column: action buttons (Edit, Delete)
          ],
        });

        // Update user info based on the DataTable
        updateUserInfo(table);

        // Listen for any redraw or page changes to update user info
        table.on("draw", function () {
          updateUserInfo(table);
        });

        // Update user info when the page changes in the DataTable
        table.on("page", function () {
          updateUserInfo(table);
        });
      } else {
        Swal.fire("Error!", response.message, "error");
      }
    } catch (error) {
      Swal.fire("Error!", error.message, "error");
    }
  };


  

  // Function to handle opening the edit modal
  const handleEditClick = (id, name, email, phoneNo, address) => {
    console.log(id)
    setIsAdding(false); // We are editing, not adding
    setEditId(id); // Set the ID of the record to be updated
    setFormData({ id, name, email, phoneNo, address }); // Set the form data with the record's data
    $("#editModal").modal("show"); // Show the modal (using Bootstrap modal's JavaScript)
  };

  // Function to handle form submission (updating data)
  const handleUpdateClick = async () => {
    try {
      const response = await UpdateUserData(formData); // Send the updated data
      if (response.status === 200) {
        Swal.fire("Success!", "Data updated successfully!", "success");
        fetchUsersData(); // Re-fetch the data to update the table
        $("#editModal").modal("hide"); // Hide the modal after success
      } else {
        Swal.fire(
          "Error!",
          "Failed to update data. Please try again.",
          "error"
        );
      }
    } catch (error) {
      Swal.fire("Error!", "An error occurred. Please try again.", "error");
    }
  };

  // Function to handle adding a new user
  const handleAddUser = async () => {
    try {
      // Prepare form data to send (ensure phoneNo is a number and no id is sent for new user)
      const userData = {
        name: formData.name,
        email: formData.email,
        phoneNo: parseInt(formData.phoneNo), // Ensure phoneNo is sent as a number
        address: formData.address,
      };

      const response = await AddUserData(userData); // Send the new data (add user)
      if (response.status === 200) {
        Swal.fire("Success!", "User added successfully!", "success");
        fetchUsersData(); // Re-fetch the data to update the table
        $("#addModal").modal("hide"); // Hide the modal after success
      } else {
        Swal.fire("Error!", "Failed to add user. Please try again.", "error");
      }
    } catch (error) {
      Swal.fire("Error!", "An error occurred. Please try again.", "error");
    }
  };

  // Function to handle deleting a user
  const handleDeleteClick = async (id) => {
    try {
      const response = await deleteUser(id); // API call to delete user data
      if (response.status === 200) {
        Swal.fire("Success!", "Data deleted successfully!", "success");
        fetchUsersData(); // Re-fetch the data to update the table
      }
    } catch (error) {
      Swal.fire(
        "Error!",
        "An error occurred while deleting data. Please try again.",
        "error"
      );
    }
  };

  return (
    <>
      <div id="user_info"></div>
      {/* Button to trigger the Add User modal */}
      <div>
        <button
          className="btn btn-success"
          onClick={() => {
            setIsAdding(true); // Set to adding mode    
            setFormData({
              id: "",
              name: "",
              email: "",
              phoneNo: "",
              address: "",
            }); // Clear form data for adding
            $("#addModal").modal("show"); // Show Add modal
          }}
        >
          Add User
        </button>
      </div>

      {/* Table to display the fetched user data */}
      <table id="user_table" className="display">
        <thead>
          <tr>
            <th>Id</th>
            <th>Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Address</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {/* Dynamically create table rows based on fetched data */}
          {usersData.map((user) => (
            <tr key={user.id}>
              <td>{user.id}</td>
              <td>{user.name}</td>
              <td>{user.email}</td>
              <td>{user.phoneNo}</td>
              <td>{user.address}</td>
              <td>
                <button
                  className="me-3 btn btn-success"
                  onClick={() =>
                   
                    handleEditClick(
                      user.id,
                      user.name,
                      user.email,
                      user.phoneNo,
                      user.address
                    )
                  }
                >
                  <i className="fa fa-edit"></i> Edit
                </button>
                <button
                  className="me-3 btn btn-danger"
                  onClick={() => handleDeleteClick(user.id)}
                >
                  <i className="fa fa-remove"></i> Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Add User Modal */}
      <div
        className="modal fade"
        id="addModal"
        tabIndex="-1"
        aria-labelledby="addModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="addModalLabel">
                {isAdding ? "Add User" : "Edit User"}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {/* Form to add/update user */}
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  value={formData.name}
                  onChange={(e) =>
                    setFormData({ ...formData, name: e.target.value })
                  }
                  placeholder="Enter Name"
                />
              </div>

              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  value={formData.email}
                  onChange={(e) =>
                    setFormData({ ...formData, email: e.target.value })
                  }
                  placeholder="Enter Email"
                />
              </div>

              <div className="mb-3">
                <label htmlFor="phoneNo" className="form-label">
                  Phone No
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="phoneNo"
                  value={formData.phoneNo}
                  onChange={(e) =>
                    setFormData({ ...formData, phoneNo: e.target.value })
                  }
                  placeholder="Enter Phone No"
                />
              </div>

              <div className="mb-3">
                <label htmlFor="address" className="form-label">
                  Address
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="address"
                  value={formData.address}
                  onChange={(e) =>
                    setFormData({ ...formData, address: e.target.value })
                  }
                  placeholder="Enter Address"
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={isAdding ? handleAddUser : handleUpdateClick}
              >
                {isAdding ? "Add" : "Update"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UsersPage;
