import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { AddServiceData, deleteService, GetServiceData, UpdateServiceData } from "../../services/serviceApis";
 

const ClientServices = () => {
  const [services, setServices] = useState([]); // State for services data
  const [formData, setFormData] = useState({
    id: "",
    service_name: "",
    service_desc: "",
  });
  const [isAdding, setIsAdding] = useState(true); // State for determining Add/Edit mode

  // Fetch services data on component mount
  useEffect(() => {
    fetchServicesData();
  }, []);

  // Function to fetch services data from API
  const fetchServicesData = async () => {
    try {
      const response = await GetServiceData();
      if (response.status === "SUCCESS") {
        const activeServices = response.data.filter(service => service.status === 1);
        setServices(activeServices);
      } else {
        Swal.fire("Error!", response.message, "error");
      }
    } catch (error) {
      Swal.fire("Error!", "Failed to fetch services data.", "error");
    }
  };

  // Function to handle opening the Edit modal with selected service data
  const handleEditClick = (id, service_name, service_desc) => {
    setIsAdding(false); // Switch to Edit mode
    setFormData({ id, service_name, service_desc });
    $("#addModal").modal("show");
  };

  // Function to handle adding a new service
  const handleAddClick = () => {
    setIsAdding(true); // Switch to Add mode
    setFormData({ id: "", service_name: "", service_desc: "" });
    $("#addModal").modal("show");
  };

  // Function to handle form submission (Add or Edit service)
  const handleFormSubmit = async () => {
    try {
      let response;
      if (isAdding) {
        response = await AddServiceData(formData); // Add new service
      } else {
        response = await UpdateServiceData(formData); // Update existing service
      }
      if (response.status === "SUCCESS") {
        Swal.fire("Success!", `${isAdding ? "Added" : "Updated"} successfully!`, "success");
        fetchServicesData(); // Re-fetch the services data
        $("#addModal").modal("hide"); // Close the modal after success
      } else {
        Swal.fire("Error!", response.message, "error");
      }
    } catch (error) {
      Swal.fire("Error!", "An error occurred while processing your request.", error.message);
    }
  };

  // Function to handle deleting a service
  const handleDeleteClick = async (id) => {
    try {
      const response = await deleteService(id);
      if (response.status === "SUCCESS") {
        Swal.fire("Success!", "Service deleted successfully!", "success");
        fetchServicesData(); // Re-fetch services data
      } else {
        Swal.fire("Error!", response.message, "error");
      }
    } catch (error) {
      Swal.fire("Error!", "Failed to delete service.", "error");
    }
  };

  return (
    <>
      <div id="demo_info"></div>

      {/* Button to trigger the Add Service modal */}
      <div>
        <button
          className="btn btn-success"
          onClick={handleAddClick}
        >
          Add Service
        </button>
      </div>

      {/* Table to display the fetched data */}
      <table id="reason_table" className="display">
        <thead>
          <tr>
            <th>Id</th>
            <th>Service Name</th>
            <th>Service Description</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {/* Dynamically create table rows based on fetched data */}
          {services.map((service) => (
            <tr key={service.id}>
              <td>{service.id}</td>
              <td>{service.service_name}</td>
              <td>{service.service_desc}</td>
              <td>
                <button
                  className="me-3 btn btn-success"
                  onClick={() => handleEditClick(service.id, service.service_name, service.service_desc)}
                >
                  <i className="fa fa-edit"></i> Edit
                </button>
                <button
                  className="me-3 btn btn-danger"
                  onClick={() => handleDeleteClick(service.id)}
                >
                  <i className="fa fa-remove"></i> Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Add/Edit Service Modal */}
      <div
        className="modal fade"
        id="addModal"
        tabIndex="-1"
        aria-labelledby="addModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="addModalLabel">
                {isAdding ? "Add Service" : "Edit Service"}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {/* Form to add/update service */}
              <div className="mb-3">
                <label htmlFor="service_name" className="form-label">
                  Service Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="service_name"
                  value={formData.service_name}
                  onChange={(e) =>
                    setFormData({ ...formData, service_name: e.target.value })
                  }
                  placeholder="Enter Service Name"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="service_desc" className="form-label">
                  Service Description
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="service_desc"
                  value={formData.service_desc}
                  onChange={(e) =>
                    setFormData({ ...formData, service_desc: e.target.value })
                  }
                  placeholder="Enter Service Description"
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleFormSubmit}
              >
                {isAdding ? "Add" : "Update"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClientServices;
