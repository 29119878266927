import router from "./apiServices";

async function GetUserData() {
  try {
    const response = await router.get("/client/get");
    return response.data;
  } catch (error) {
    // Handle error, e.g., log it or throw a custom error
    console.error("Error in adding status:", error);
    throw error;
  }
}

async function UpdateUserData(formData) {
  try {
    const response = await router.put(
      `/client/update`,
      formData
    );
    return response.data;
  } catch (error) {
    // Handle error, e.g., log it or throw a custom error
    console.error("Error in adding status:", error);
    throw error;
  }
}

async function AddUserData(formData) {
  try {
    const response = await router.post("/client/add", formData);

    return response;
  } catch (error) {
    // Handle error, e.g., log it or throw a custom error
    console.error("Error in adding status:", error);
    throw error;
  }
}

async function deleteUser(id) {
  try {
    const response = await router.post(`/client/delete/${id}`);
    return response;
  } catch (error) {
    // Handle error, e.g., log it or throw a custom error
    console.error("Error in adding status:", error);
    throw error;
  }
}

export { GetUserData, UpdateUserData, AddUserData, deleteUser };
