import "./App.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import UserLayout from "./component/layout/userLayout";
import ReasonPage from "./component/pages/Reason/reason";
import Dashboard from "./component/pages/dashboard/dashboard";
import ClientServices from "./component/pages/clientServices/clientServices";
import UsersPage from "./component/pages/users/users";
import CustomLogin from "./component/Auth/CustomLogin";
import { AuthProvider } from "./component/authPage/contex";
import ProtectedRoute from "./component/authPage/protectRoutes";
function App() {
  return (
    <>
      <AuthProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Navigate to="/dashboard" />} />
            <Route path="/login" element={<CustomLogin />}></Route>
            <Route path="/" element={<UserLayout />}>
              <Route
                path="dashboard"
                element={
                  // <ProtectedRoute>
                    <Dashboard />
                  // </ProtectedRoute>
                }
              />{" "}
              <Route
                path="reason"
                element={
                  // <ProtectedRoute>
                    <ReasonPage />
                  // </ProtectedRoute>
                }
              />{" "}
              <Route
                path="services"
                element={
                  // <ProtectedRoute>
                    <ClientServices />
                  // </ProtectedRoute>
                }
              />
              <Route
                path="users"
                element={
                  // <ProtectedRoute>
                    <UsersPage />
                  // {/* </ProtectedRoute> */}
                }
              />
            </Route>
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </>
  );
}

export default App;
