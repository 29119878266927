import router from "./apiServices";

async function GetGraphMonthData() {
  try {
    const response = await router.get("/client-per-day");
    return response.data;
  } catch (error) {
    // Handle error, e.g., log it or throw a custom error
    console.error("Error in adding status:", error);
    throw error;
  }
}

async function GetGraphYearData() {
  try {
    const response = await router.get("/client-per-month");
    return response.data;
  } catch (error) {
    // Handle error, e.g., log it or throw a custom error
    console.error("Error in adding status:", error);
    throw error;
  }
}

async function GetTodayMonthAddData() {
    try {
      const response = await router.get("/monthly_addClient");
      return response.data;
    } catch (error) {
      // Handle error, e.g., log it or throw a custom error
      console.error("Error in adding status:", error);
      throw error;
    }
  }


  async function GetTodayTotalAddData() {
    try {
      const response = await router.get("/total_today_addClient");
      return response.data;
    } catch (error) {
      // Handle error, e.g., log it or throw a custom error
      console.error("Error in adding status:", error);
      throw error;
    }
  }


  async function GetTodayAddClientTableData() {
    try {
      const response = await router.get("/todayCllient_details");
      return response.data;
    } catch (error) {
      // Handle error, e.g., log it or throw a custom error
      console.error("Error in adding status:", error);
      throw error;
    }
  }


 


export   {
  GetGraphMonthData,
  GetGraphYearData,
  GetTodayMonthAddData,
  GetTodayTotalAddData ,
  GetTodayAddClientTableData
};
