import router from "./apiServices";

async function GetReasonData() {
  try {
    const response = await router.get("/reason/get");
    return response.data;
  } catch (error) {
    // Handle error, e.g., log it or throw a custom error
    console.error("Error in adding status:", error);
    throw error;
  }
}

async function UpdateReasonData(formData) {
  try {
    const response = await router.put(
      `/reason/update/${formData.id}`,
      formData
    );
    return response;
  } catch (error) {
    // Handle error, e.g., log it or throw a custom error
    console.error("Error in adding status:", error);
    throw error;
  }
}

async function AddReasonData(formData) {
  try {
    const response = await router.post("/reason/add", formData);

    return response.data;
  } catch (error) {
    // Handle error, e.g., log it or throw a custom error
    console.error("Error in adding status:", error);
    throw error;
  }
}

async function deleteReason(id) {
  try {
    const response = await router.post(`/reason/delete/${id}`);
    return response.data;
  } catch (error) {
    // Handle error, e.g., log it or throw a custom error
    console.error("Error in adding status:", error);
    throw error;
  }
}

export { GetReasonData, UpdateReasonData, AddReasonData, deleteReason };
