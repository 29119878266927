import router from "./apiServices";

async function GetServiceData() {
  try {
    const response = await router.get("/service/get");
    return response.data;
  } catch (error) {
    // Handle error, e.g., log it or throw a custom error
    console.error("Error in adding status:", error);
    throw error;
  }
}

async function UpdateServiceData(formData) {
  try {
    const response = await router.put(
      `/service/update`,
      formData
    );
    return response.data;
  } catch (error) {
    // Handle error, e.g., log it or throw a custom error
    console.error("Error in adding status:", error);
    throw error;
  }
}

async function AddServiceData(formData) {
  try {
    const response = await router.post("/service/add", formData);

    return response.data;
  } catch (error) {
    // Handle error, e.g., log it or throw a custom error
    console.error("Error in adding status:", error);
    throw error;
  }
}

async function deleteService(id) {
  try {
    const response = await router.post(`/service/delete/${id}`);
    return response.data;
  } catch (error) {
    // Handle error, e.g., log it or throw a custom error
    console.error("Error in adding status:", error);
    throw error;
  }
}

export { GetServiceData, UpdateServiceData, AddServiceData, deleteService };
