import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer className="text-center text-lg-start bg-dark text-white mt-auto">
        <section className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
          <div className="me-5 d-none d-lg-block">
            <span>Get connected with us on social networks:</span>
          </div>
          <div>
            <a href="#" className="me-4 text-reset">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a href="#" className="me-4 text-reset">
              <i className="fab fa-twitter"></i>
            </a>
            <a href="#" className="me-4 text-reset">
              <i className="fab fa-google"></i>
            </a>
            <a href="#" className="me-4 text-reset">
              <i className="fab fa-instagram"></i>
            </a>
            <a href="#" className="me-4 text-reset">
              <i className="fab fa-linkedin"></i>
            </a>
            <a href="#" className="me-4 text-reset">
              <i className="fab fa-github"></i>
            </a>
          </div>
        </section>

        <section>
          <div className="container text-center text-md-start mt-5">
            <div className="row mt-3">
              {/* Company Description */}
              <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold mb-4">
                  <i className="fas fa-gem me-3"></i>Digital Add World
                </h6>
                <p>
                  We at Digitaladdworld Tech are proud to say that we are a top-notch 
                  digital marketing agency fully committed to providing innovative IT Solutions. Even though our quest commenced on January 
                  16, 2023, we have quickly become a go-to agency for businesses needing online presence elevation.
                </p>
              </div>

              {/* Useful Links */}
              <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold mb-4">Useful links</h6>
                <p>
                  <a
                    href="https://digitaladdworld.in"
                    className="text-reset"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Digital Add World
                  </a>
                </p>
                <p>
                  <a
                    href="https://kheloexchanges.com"
                    className="text-reset"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Khelo Exchanges
                  </a>
                </p>
                <p>
                  <a
                    href="http://digitalpaymoney.com"
                    className="text-reset"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Digital Pay Money
                  </a>
                </p>
              </div>

              {/* Contact Info */}
              <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                <h6 className="text-uppercase fw-bold mb-4">Contact</h6>
                <p>
                  <i className="fas fa-home me-3"></i> A-124 Near Sanganer Road,
                  Dadu Dayal Nagar, Kalyanpura, Sanganer, Jaipur-302020, Rajasthan
                </p>
                <p>
                  <i className="fas fa-envelope me-3"></i> hrteam@digitaladdworld.in
                </p>
                <p>
                  <i className="fas fa-phone me-3"></i> +01 234 567 88
                </p>
                <p>
                  <i className="fas fa-print me-3"></i> +01 234 567 89
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Footer Bottom */}
        <div
          className="text-center p-4"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
        >
          © 2021 Copyright:{" "}
          <a
            href="https://mdbootstrap.com/"
            className="text-reset fw-bold"
            target="_blank"
            rel="noopener noreferrer"
          >
            MDBootstrap.com
          </a>
        </div>
      </footer>
    </>
  );
};

export default Footer;
