import { Link } from "react-router-dom";

const Sidebar = ({ isCollapsed, onToggle, handleAuthPagesClick }) => {
  return (
    <>
      <div className={`sidebar ${isCollapsed ? 'collapsed' : ''}`} id="sidebar">
        <button
          className="btn btn-primary ms-3"
          id="toggleSidebarBtn"
          onClick={onToggle} // Use the onToggle prop here
        >
          <span id="sidebarIcon" className="navbar-toggler-icon">
            <i className="bi bi-text-indent-left"></i>
          </span>
        </button>

        <div id="sidebarContent">
          <Link to="/dashboard">
            <i className="bi bi-house"></i> 
            <span className={`sidebar-text ${isCollapsed ? 'd-none' : ''}`}> Dashboard</span>
          </Link>
          <Link to="/users">
            <i className="bi bi-person-circle"></i>
            <span className={`sidebar-text ${isCollapsed ? 'd-none' : ''}`}> Users Management </span>
          </Link>
          <Link to="/services">
            <i className="bi bi-person-circle"></i>
            <span className={`sidebar-text ${isCollapsed ? 'd-none' : ''}`}> Services </span>
          </Link>
          <Link to="/reason">
            <i className="bi bi-person-circle"></i>
            <span className={`sidebar-text ${isCollapsed ? 'd-none' : ''}`}> Reasons </span>
          </Link>
          <a href="#" id="authPagesLink" onClick={handleAuthPagesClick}>
            <i className="bi bi-person-circle"></i>
            <span className={`sidebar-text ${isCollapsed ? 'd-none' : ''}`}> Pages</span>
          </a>
          <ul id="authPagesSublist" style={{ display: "none" }}>
            <li>
              <Link to="/signUp">
                <i className="bi bi-person-circle"></i>
                <span className={`sidebar-text ${isCollapsed ? 'd-none' : ''}`}> Sign Up 1</span>
              </Link>
            </li>
            <li>
              <Link to="/signUp2">
                <i className="bi bi-person-circle"></i>
                <span className={`sidebar-text ${isCollapsed ? 'd-none' : ''}`}> Sign Up 2</span>
              </Link>
            </li>
            <li>
              <Link to="/signIn1">
                <i className="bi bi-person-circle"></i>
                <span className={`sidebar-text ${isCollapsed ? 'd-none' : ''}`}> Sign In 1</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
