import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import {
  GetGraphMonthData,
  GetGraphYearData,
  GetTodayAddClientTableData,
  GetTodayTotalAddData,
  GetTodayMonthAddData,
} from "../../services/dashboardService";

export default function Dashboard() {
  const [chartData, setChartData] = useState({
    datesArray: [],
    clientCountArray: [],
  });
  const [monthlyData, setMonthlyData] = useState({
    datesArray: [],
    clientCountArray: [],
  });
  const [yearlyData, setYearlyData] = useState({
    monthsArray: [],
    clientCountArray: [],
  });
  const [totalClientAddTodayDetails, setTotalClientAddTodayDetails] = useState(
    []
  );
  const [totalClientThisTodayCard, setTotalClientTodayCard] = useState(0);
  const [totalClientThisMonthCard, setTotalClientThisMonthCard] = useState(0);

  const showMonthlyData = () => {
    setChartData(monthlyData);
  };

  const showYearlyData = () => {
    setChartData(yearlyData);
  };

  useEffect(() => {
    fetchMonthGraphData();
    fetchYearGraphData();
    fetchTodayAddClientTableData();
    fetchTodayTotalAddData();
    fetchTodayMonthAddData();
  }, []);

  const fetchMonthGraphData = async () => {
    try {
      const response = await GetGraphMonthData();
      if (response.status === "SUCCESS" && response.data) {
        setMonthlyData(response.data);
        setChartData(response.data);
      } else {
        console.error("No month data found or API failed");
        setMonthlyData({ datesArray: [], clientCountArray: [] });
      }
    } catch (error) {
      console.error("Error fetching month graph data:", error);
      setMonthlyData({ datesArray: [], clientCountArray: [] });
    }
  };

  const fetchYearGraphData = async () => {
    try {
      const response = await GetGraphYearData();
      console.log(response)
      if (response.status === "SUCCESS" && response.data) {
        setYearlyData(response.data);
      } else {
        console.error("No year data found or API failed");
        setYearlyData({ monthsArray: [], clientCountArray: [] });
      }
    } catch (error) {
      console.error("Error fetching year graph data:", error);
      setYearlyData({ monthsArray: [], clientCountArray: [] });
    }
  };

  const fetchTodayAddClientTableData = async () => {
    try {
      const response = await GetTodayAddClientTableData();
      if (response.status === "SUCCESS" && response.data) {
        setTotalClientAddTodayDetails(response.data);
      } else {
        console.error("No data found for today's added clients or API failed");
        setTotalClientAddTodayDetails([]);
      }
    } catch (error) {
      console.error("Error fetching today's added client data:", error);
      setTotalClientAddTodayDetails([]);
    }
  };

  const fetchTodayTotalAddData = async () => {
    try {
      const response = await GetTodayTotalAddData();
      if (response.status === "SUCCESS" && response.data) {
        setTotalClientTodayCard(response.data[0]?.add_today_clients || 0);
      } else {
        console.error("No total client data found for today or API failed");
        setTotalClientTodayCard(0);
      }
    } catch (error) {
      console.error("Error fetching total client data for today:", error);
      setTotalClientTodayCard(0);
    }
  };

  const fetchTodayMonthAddData = async () => {
    try {
      const response = await GetTodayMonthAddData();
      if (response.status === "SUCCESS" && response.data) {
        setTotalClientThisMonthCard(response.data[0]?.add_month_clients || 0);
      } else {
        console.error("No total client data found for this month or API failed");
        setTotalClientThisMonthCard(0);
      }
    } catch (error) {
      console.error("Error fetching total client data for this month:", error);
      setTotalClientThisMonthCard(0);
    }
  };

  return (
    <>
      <div className="container mt-5 pt-4 pb-2">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="graph-container">
              <div className="graph-buttons">
                <button className="btn btn-success me-2" onClick={showMonthlyData}>
                  Monthly View
                </button>
                <button className="btn btn-primary me-2" onClick={showYearlyData}>
                  Yearly View
                </button>
              </div>
              <div className="chart-container">
                <ResponsiveContainer width="100%" height={300}>
                  <LineChart
                    data={chartData.clientCountArray.map((count, index) => ({
                      x: chartData.monthsArray
                        ? chartData.monthsArray[index]
                        : chartData.datesArray[index],
                      y: count,
                    }))}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="x" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="y" stroke="#8884d8" activeDot={{ r: 8 }} />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container my-4">
        <div className="row">
          <div className="col-md-6 mb-3">
            <div className="card">
              <div className="card-header">Today</div>
              <div className="card-body">
                <h5 className="card-title">Total Client</h5>
                <p className="card-text">{totalClientThisTodayCard}</p>
              </div>
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <div className="card">
              <div className="card-header">This Month</div>
              <div className="card-body">
                <h5 className="card-title">Total Client</h5>
                <p className="card-text">{totalClientThisMonthCard}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-md-12 mb-3">
          <table className="table table-striped">
            <thead className="table-dark">
              <tr>
                <th scope="col">S.N</th>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">Phone</th>
                <th scope="col">Address</th>
              </tr>
            </thead>
            <tbody>
              {totalClientAddTodayDetails.map((items, index) => (
                <tr key={index}>
                  <th>{index + 1}</th>
                  <td>{items.name}</td>
                  <td>{items.email}</td>
                  <td>{items.phoneNo}</td>
                  <td>{items.address}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
