import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import {
  AddReasonData,
  deleteReason,
  GetReasonData,
  UpdateReasonData,
} from "../../services/reasonServices";

const ReasonPage = () => {
  const [reasonData, setReasonData] = useState([]); // Data for reasons
  const [formData, setFormData] = useState({ id: "", reason_name: "" }); // Form data
  const [isAdding, setIsAdding] = useState(true); // Add or Edit mode
  const [editId, setEditId] = useState(null); // ID of the record being edited

  useEffect(() => {
    fetchReasonData();
  }, []);

  const fetchReasonData = async () => {
    try {
      const response = await GetReasonData();
      if (response?.status === "SUCCESS") {
        const data = response.data || [];
        setReasonData(data);

        // Initialize or reinitialize DataTable
        if ($.fn.DataTable.isDataTable("#reason_table")) {
          $("#reason_table").DataTable().destroy();
        }

        const table = $("#reason_table").DataTable({
          data: data,
          columns: [
            { data: "id" },
            { data: "reason_name" },
            {
              data: null,
              defaultContent: `
                <button class="me-3 btn btn-success"><i class="fa fa-edit"></i> Edit</button>
                <button class="me-3 btn btn-danger"><i class="fa fa-remove"></i> Delete</button>
              `,
            },
          ],
          destroy: true,
        });

        // Bind actions to the Edit/Delete buttons
        table.on("click", "button.btn-success", function () {
          const rowData = table.row($(this).parents("tr")).data();
          handleEditClick(rowData.id, rowData.reason_name);
        });

        table.on("click", "button.btn-danger", function () {
          const rowData = table.row($(this).parents("tr")).data();
          handleDeleteClick(rowData.id);
        });
      } else {
        Swal.fire("Error!", "Failed to fetch reasons. Please try again.", "error");
      }
    } catch (error) {
      Swal.fire("Error!", "An unexpected error occurred. Please try again.", "error");
    }
  };

  const handleEditClick = (id, reason_name) => {
    setIsAdding(false);
    setEditId(id);
    setFormData({ id, reason_name });
    $("#addModal").modal("show");
  };

  const handleAddClick = () => {
    setIsAdding(true);
    setFormData({ id: "", reason_name: "" });
    $("#addModal").modal("show");
  };

  const handleUpdateClick = async () => {
    try {
      const response = await UpdateReasonData(formData);
      if (response?.status === "SUCCESS") {
        Swal.fire("Success!", "Reason updated successfully!", "success");
        fetchReasonData();
        $("#addModal").modal("hide");
      } else {
        Swal.fire("Error!", "Failed to update reason. Please try again.", "error");
      }
    } catch (error) {
      Swal.fire("Error!", "An error occurred. Please try again.", "error");
    }
  };

  const handleAddReason = async () => {
    try {
      const reasonData = { reason_name: formData.reason_name };
      const response = await AddReasonData(reasonData);
      if (response?.status === "SUCCESS") {
        Swal.fire("Success!", "Reason added successfully!", "success");
        fetchReasonData();
        $("#addModal").modal("hide");
      } else {
        Swal.fire("Error!", "Failed to add reason. Please try again.", "error");
      }
    } catch (error) {
      Swal.fire("Error!", "An error occurred. Please try again.", "error");
    }
  };

  const handleDeleteClick = async (id) => {
    try {
      const response = await deleteReason(id);
      if (response?.status === "SUCCESS") {
        Swal.fire("Success!", "Reason deleted successfully!", "success");
        fetchReasonData();
      } else {
        Swal.fire("Error!", "Failed to delete reason. Please try again.", "error");
      }
    } catch (error) {
      Swal.fire("Error!", "An error occurred while deleting data. Please try again.", "error");
    }
  };

  return (
    <>
      <div>
        <button className="btn btn-success" onClick={handleAddClick}>
          Add Reason
        </button>
      </div>

      <table id="reason_table" className="display">
        <thead>
          <tr>
            <th>Id</th>
            <th>Name</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody></tbody>
      </table>

      <div
        className="modal fade"
        id="addModal"
        tabIndex="-1"
        aria-labelledby="addModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="addModalLabel">
                {isAdding ? "Add Reason" : "Edit Reason"}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="mb-3">
                <label htmlFor="reason_name" className="form-label">
                  Reason Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="reason_name"
                  value={formData.reason_name}
                  onChange={(e) =>
                    setFormData({ ...formData, reason_name: e.target.value })
                  }
                  placeholder="Enter Reason Name"
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={isAdding ? handleAddReason : handleUpdateClick}
              >
                {isAdding ? "Add" : "Update"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReasonPage;
