import router, { setAuthToken } from "./apiServices";

async function LoginApi(formData) {
  try {
    const response = await router.post("/login", formData);
    setAuthToken(response.data?.token)
    return response.data;
  } catch (error) {
    // Handle error, e.g., log it or throw a custom error
    console.error("Error in adding status:", error);
    throw error;
  }
}

export default LoginApi;
