import { Outlet } from "react-router-dom";

import { useEffect, useState } from "react";
import Sidebar from "../common/sidebar";
import Header from "../common/header";
import Footer from "../common/footer";

const UserLayout = () => {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

  useEffect(() => {
    adjustSidebarPosition();
  }, []);

  function adjustSidebarPosition() {
    const headerHeight = document.querySelector("nav")?.offsetHeight;
    const sidebar = document.querySelector(".sidebar");
    if (sidebar && headerHeight) {
      sidebar.style.top = headerHeight + "px";
    }
  }

  const handleSidebarToggle = () => {
    setIsSidebarCollapsed((prev) => !prev);
  };

  const handleAuthPagesClick = (e) => {
    e.preventDefault();
    const authPagesSublist = document.getElementById("authPagesSublist");
    if (authPagesSublist) {
      authPagesSublist.style.display =
        authPagesSublist.style.display === "none" ? "block" : "none";
    }
  };

  return (
    <>
      <Sidebar
        isCollapsed={isSidebarCollapsed}
        onToggle={handleSidebarToggle}
        handleAuthPagesClick={handleAuthPagesClick}
      />
      <div className="content" id="content">
        <Header />
        <div className="container mt-5 pt-4 pb-2">
          <main>
            <Outlet />
          </main>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default UserLayout;
