import React, { createContext, useState, useMemo, useContext, useEffect } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [authToken, setAuthToken] = useState(null);
    const [userData, setUserData] = useState(null);

    // Check if there's a token in localStorage and set it on component mount
    useEffect(() => {
        const savedToken = localStorage.getItem('authToken');
        if (savedToken) {
            setAuthToken(savedToken);
            // Optionally decode token to get user data (if your token contains user info)
            const decodedUserData = JSON.parse(atob(savedToken.split('.')[1])); // Assuming JWT token
            setUserData(decodedUserData);
        }
    }, []);

    const setToken = (token) => {
        setAuthToken(token);
        // Save token to localStorage
        localStorage.setItem('authToken', token);
    };

    const logout = () => {
        localStorage.removeItem('authToken');
        setAuthToken(null);
        setUserData(null);
    };

    const setData = (data) => {
        setUserData(data);
    };

    const isAuthenticated = !!authToken;

    const contextValue = useMemo(() => ({
        authToken,
        setToken,
        userData,
        setData,
        logout,
        isAuthenticated,
    }), [authToken, userData]);

    return (
        <AuthContext.Provider value={contextValue}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error("useAuth must be used within an AuthProvider");
    }
    return context;
};
