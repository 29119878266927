import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../authPage/contex";  // Assuming you have a context for authentication
import Swal from "sweetalert2";
import LoginApi from "../services/authServices";
import {jwtDecode}  from "jwt-decode";


const CustomLogin = () => {
 
      const [email, setemail] = useState("");
      const [password, setPassword] = useState("");
      const navigate = useNavigate();
      const { setToken, setData } = useAuth();
    
      const handleLogin = async (e) => {
        e.preventDefault();
        try {
          const response = await LoginApi({ email, password });
          console.log(response);
    
          if (response.data.token) {
            // Save the token and set the user data in the context
            localStorage.setItem('authToken', response.data.token);
            setToken(response.data.token);
    
            // Optionally, decode the token if it contains user data
            const decodedData = JSON.parse(atob(response.data.token.split('.')[1]));
            setData(decodedData);
    
            // Redirect to dashboard
            navigate('/dashboard');
          }
        } catch (error) {
          Swal.fire({
            title: 'Error!',
            text: 'Incorrect username or password. Please try again.',
            icon: 'error',
            confirmButtonText: 'Retry',
          });
        }
      };

  return (
    <section className="background-radial-gradient overflow-hidden">
      <div className="container px-4 py-5 px-md-5 text-center text-lg-start my-5">
        <div className="row gx-lg-5 align-items-center mb-5">
          <div className="col-lg-6 mb-5 mb-lg-0" style={{ zIndex: "10" }}>
            <h1 className="my-5 display-5 fw-bold ls-tight text-light">
              Customer Data <br />
              <span style={{ color: "hsl(218, 81%, 75%)" }}>Platform</span>
            </h1>
            <p
              className="mb-4 opacity-70"
              style={{ color: " hsl(218, 81%, 85%) " }}
            >
              Another meaning of CRM content could be the reports or analytics
              generated by the CRM system to track performance, sales, and
              customer engagement.
            </p>
          </div>

          <div className="col-lg-6 mb-5 mb-lg-0 position-relative">
            <div
              id="radius-shape-1"
              className="position-absolute rounded-circle shadow-5-strong"
            ></div>
            <div
              id="radius-shape-2"
              className="position-absolute shadow-5-strong"
            ></div>

            <div className="card bg-glass">
              <div className="card-body px-4 py-5 px-md-5">
                <form onSubmit={handleLogin}>
                  <img src="./assets/img/crmLogo.png" className="loing-img" />

                  {/* Email input */}
                  <div data-mdb-input-init className="form-outline mb-4">
                    <input
                      type="email"
                      id="form3Example3"
                      className="form-control"
                      value={email}
                      onChange={(e) => setemail(e.target.value)}
                    />
                    <label className="form-label" htmlFor="form3Example3">
                      Email address
                    </label>
                  </div>

                  {/* Password input */}
                  <div data-mdb-input-init className="form-outline mb-4">
                    <input
                      type="password"
                      id="form3Example4"
                      className="form-control"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <label className="form-label" htmlFor="form3Example4">
                      Password
                    </label>
                  </div>

                  {/* Submit button */}
                  <button
                    type="submit"
                    data-mdb-button-init
                    data-mdb-ripple-init
                    className="btn btn-primary btn-block mb-4 w-100"
                    style={{ backgroundColor: "#44006b", fontWeight: "600" }}
                  >
                    Sign In
                  </button>

                  {/* Register buttons */}
                  <div className="text-center">
                    <p>or sign up with:</p>
                    <button
                      type="button"
                      data-mdb-button-init
                      data-mdb-ripple-init
                      className="btn btn-link btn-floating mx-1"
                    >
                      <i className="fab fa-facebook-f"></i>
                    </button>

                    <button
                      type="button"
                      data-mdb-button-init
                      data-mdb-ripple-init
                      className="btn btn-link btn-floating mx-1"
                    >
                      <i className="fab fa-google"></i>
                    </button>

                    <button
                      type="button"
                      data-mdb-button-init
                      data-mdb-ripple-init
                      className="btn btn-link btn-floating mx-1"
                    >
                      <i className="fab fa-twitter"></i>
                    </button>

                    <button
                      type="button"
                      data-mdb-button-init
                      data-mdb-ripple-init
                      className="btn btn-link btn-floating mx-1"
                    >
                      <i className="fab fa-github"></i>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CustomLogin;
