import { Link, useNavigate } from "react-router-dom"; // Import Link for navigation
import { useAuth } from "../authPage/contex"; // Assuming you have a context for authentication

const Header = () => {
  const { logout } = useAuth();
  const navigate = useNavigate(); // Hook to navigate to a different route

  const handleLogOut = async () => {
    try {
      await logout(); // Call the logout function
      navigate("/login"); // Redirect to the login page
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
        {/* Sidebar Toggle Button */}
        <button
          className="navbar-toggler d-lg-none"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#mobileSidebar"
          aria-controls="mobileSidebar"
          aria-label="Toggle sidebar"
        >
          <i className="bi bi-list"></i>
        </button>

        {/* Brand Logo */}
        <Link className="navbar-brand d-flex justify-content-center" to="/">
          <img
            src="./assets/img/crmLogo.png"
            alt="Brand Logo"
            className="h-25 w-25 rounded-circle"
          />
        </Link>

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i className="bi bi-list"></i>
        </button>

        {/* Navbar Content */}
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item dropdown">
              <Link
                to="#"
                className="dropdown-toggle nav-link"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span className="user-img">
                  <img
                    src="./assets/img/profile.jpg"
                    alt="User Profile"
                    className="rounded-circle"
                    width="50px"
                    height="50px"
                  />
                  <span className="status online"></span>
                </span>
              </Link>
              <div className="dropdown-menu dropdown-menu-end">
                <div className="profilename">
                  <div className="profileset d-flex align-items-center">
                    <img
                      src="./assets/img/profile.jpg"
                      alt="User Profile"
                      className="rounded-circle m-2"
                      width="50px"
                      height="50px"
                    />
                    <div>
                      <h6 className="mb-0">John Doe</h6>
                      <small>Admin</small>
                    </div>
                  </div>
                </div>
                <hr className="m-0" />
                <Link className="dropdown-item" to="/profile">
                  <i className="me-2" data-feather="user"></i> My Profile
                </Link>
                <Link className="dropdown-item" to="/generalsettings">
                  <i className="me-2" data-feather="settings"></i> Settings
                </Link>
                <hr className="m-0" />
                <button
                  className="dropdown-item logout pb-0"
                  onClick={handleLogOut}
                >
                  <i
                    className="bi bi-box-arrow-right"
                    style={{ fontSize: "20px" }}
                  ></i>
                  Logout
                </button>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default Header;
